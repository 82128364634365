import { AppPositionElement } from '@models/positions';
import { ApplicantElement, ApplicantsElement, CorrelationPositionsType, PatchApplicantModel } from '@models/applicants';
import axiosInstance from '../../utils/http-client';
import dayjs from 'dayjs';
import { SentEmailApplicantElement } from '@models/email-templates';

export const getAllApplicants = (
  PageNumber?: number,
  PageSize?: number,
  keyword?: string,
  viewed?: boolean,
  fromDate?: Date,
  toDate?: Date,
  tags?: Array<number>,
  positions?: Array<number>,
  source?: Array<string>,
  status?: string,
  commentStatus?: boolean,
  order?: string,
  orderBy?: string,
  lang?: string,
): Promise<ApplicantsElement> => {
  const parameters: { params: any; paramsSerializer: any } = {
    params: {},
    paramsSerializer: { indexes: null },
  };

  if (keyword) {
    parameters.params.keyword = keyword;
  }
  if (viewed === false) parameters.params.viewed = false;
  if (viewed === true) parameters.params.viewed = true;
  if (lang) parameters.params.lang = lang;

  if (fromDate) {
    parameters.params.createdFrom = fromDate;
    if (!toDate) {
      parameters.params.createdTo = dayjs().format('YYYY-MM-DD');
    } else {
      parameters.params.createdTo = toDate;
    }
  }

  if (tags && tags.length > 0) {
    parameters.params.tags = tags;
  }
  if (positions && positions.length > 0) {
    parameters.params.positions = positions;
  }

  if (commentStatus !== undefined) {
    parameters.params.comments = commentStatus;
  }

  if (source && source.length > 0) {
    parameters.params.source = source;
  }
  if (orderBy) {
    parameters.params.orderBy = orderBy;
    parameters.params.order = order;
  }

  if (status) {
    parameters.params.status = status;
  }

  return axiosInstance.get(`/api/v1/app/applicants?PageNumber=${PageNumber}&PageSize=${PageSize}`, parameters);
};

export const getApplicant = (id: number, lang?: string): Promise<ApplicantElement> => {
  return axiosInstance.get(`/api/v1/app/applicants/${id}`, {
    params: { lang },
  });
};

export const createApplicant = (
  firstName: string,
  lastName: string,
  customLink?: string,
  email?: string,
  mobilePhoneNumber?: string,
): Promise<number> => {
  return axiosInstance.post('/api/v1/app/applicants', {
    firstName,
    lastName,
    customLink,
    email,
    mobilePhoneNumber,
  });
};

export const uploadCV = (cvId: string, cvFile: FormData): Promise<ApplicantElement> => {
  return axiosInstance.post(`/api/v1/app/applicants/${cvId}/cvFile`, cvFile);
};

export const getCorrelatedPositions = (id: number): Promise<CorrelationPositionsType> => {
  return axiosInstance.get(`/api/v1/app/positions/uncorrelated/applicant/${id}`, {});
};

export const postCorrelatedPositions = (id: number, positionIds: Array<Number>): Promise<CorrelationPositionsType> => {
  return axiosInstance.post(`/api/v1/app/applicant/${id}/positions`, {
    posIds: positionIds,
  });
};

export const getCorrelatedPositionsByTitle = (id: number): Promise<CorrelationPositionsType> => {
  return axiosInstance.get(`/api/v1/app/positions/uncorrelated/applicant/${id}?orderBy=title&order=asc`, {});
};

export const patchRejectApplicant = (
  position: string,
  applicantId: string,
  stepId: string,
  rejectedFlag: boolean,
): Promise<AppPositionElement> => {
  return axiosInstance.patch(`/api/v1/app/positions/${position}/workflow-steps/applicant`, {
    applicantId,
    stepId,
    rejectedFlag,
  });
};

export const postHireApplicant = (positionId: string, applicantId: string): Promise<AppPositionElement> => {
  return axiosInstance.post(`/api/v1/app/applicants/hire`, {
    applicantId,
    positionId,
  });
};

export const downloadCvUrl = (id: string): Promise<string> => {
  return axiosInstance.get(`/api/v1/app/applicants/cv/${id}`, {});
};

export const patchCandidate = (candidateValues: PatchApplicantModel, id: string): Promise<ApplicantElement> => {
  return axiosInstance.patch(`/api/v1/app/applicants/${id}`, {
    id: candidateValues.id,
    firstName: candidateValues.firstName,
    lastName: candidateValues.lastName,
    email: candidateValues.email,
    mobilePhoneNumber: candidateValues.mobilePhoneNumber,
    phoneNumber: candidateValues.phoneNumber,
    residentialArea: candidateValues.residentialArea,
    address: candidateValues.address,
    addressNumber: candidateValues.addressNumber,
    postCode: candidateValues.postCode,
    dateOfBirth: candidateValues.dateOfBirth,
    educationLevel: candidateValues.educationLevel,
    linkedin: candidateValues.linkedin,
  });
};

export const postTag = (id: string, tag: string): Promise<Array<{ id: number; title: string }>> => {
  return axiosInstance.post(`/api/v1/app/applicants/${id}/tags`, {
    tag,
  });
};

export const deleteTag = (applicantId: string, tagId: string): Promise<any> => {
  return axiosInstance.delete(`/api/v1/app/applicants/${applicantId}/tags/${tagId}`, {});
};

export const getUnviewedCount = (
  keyword?: string,
  fromDate?: Date,
  toDate?: Date,
  tags?: Array<number>,
  positions?: Array<number>,
  source?: Array<string>,
  status?: string,
  commentStatus?: boolean,
  lang?: string,
): Promise<any> => {
  const parameters: { params: any; paramsSerializer: any } = {
    params: {},
    paramsSerializer: { indexes: null },
  };
  if (keyword) {
    parameters.params.keyword = keyword;
  }

  if (fromDate && fromDate !== undefined) {
    parameters.params.createdFrom = fromDate;
    if (!toDate) {
      parameters.params.createdTo = dayjs().format('YYYY-MM-DD');
    } else {
      parameters.params.createdTo = toDate;
    }
  }

  if (lang) parameters.params.lang = lang;

  if (tags && tags.length > 0) {
    parameters.params.tags = tags;
  }

  if (positions && positions.length > 0) {
    parameters.params.positions = positions;
  }

  if (commentStatus !== undefined) {
    parameters.params.comments = commentStatus;
  }

  if (source && source.length > 0) {
    parameters.params.source = source;
  }

  if (status) {
    parameters.params.status = status;
  }

  return axiosInstance.get('api/v1/app/applicants?viewed=false', parameters);
};

export const getApplicantEmailTemplates = (applicantId: string): Promise<Array<SentEmailApplicantElement>> => {
  return axiosInstance.get(`/api/v1/app/email/sent?applicantId=${applicantId}`, {});
};
