import React, { useState } from 'react';
import { useRouter } from 'next/router';
import StateEnum from '@constants/state.enum';
import useNotification from '@hooks/notification/useNotification';
import { Form, Modal, Input, Spin } from 'antd';
import { createPosition, updatePosition } from '@services/position/position-http.service';
import UiButton from '@components/ui/button/UiButton';
import { AppPositionElement } from '@models/positions';
import { useTranslation } from 'next-i18next';
import styles from './TitleModal.module.scss';

type TitleModalProps = {
  openTitleModal: boolean;
  setOpenTitleModal: (value: boolean) => void;
  setTitle?: (value: string) => void;
  titleValue?: string;
  positionId?: string;
};

const TitleModal: React.FC<TitleModalProps> = ({
  openTitleModal,
  setOpenTitleModal,
  setTitle,
  titleValue,
  positionId,
}) => {
  const { t: translate, i18n } = useTranslation('common');
  const router = useRouter();
  const [modalDisable, setModalDisable] = useState<boolean>(!titleValue);
  const [creating, setCreating] = useState<boolean>(false);
  const [submitGoTo, setSubmitGoTo] = useState<'position' | 'applicants'>();
  const [form] = Form.useForm();

  const [generalError] = useNotification(
    StateEnum.ERROR,
    'Something went wrong',
    'Oops, something went wrong, please try again later!',
  );
  const [successPositionCreate] = useNotification(
    StateEnum.SUCCESS,
    translate('create_position.position_created'),
    translate('create_position.position_created_success'),
  );
  const [successPositionUpdated] = useNotification(
    StateEnum.SUCCESS,
    translate('create_position.position_updated'),
    translate('create_position.position_updated_success'),
  );

  const closeModal = () => {
    setCreating(false);
    setModalDisable(!titleValue);
    setOpenTitleModal(false);
  };

  const onFinish = async (values: { title: string }) => {
    const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

    setCreating(true);
    if (!positionId && values.title.trimStart()) {
      try {
        const newPosition = await createPosition({
          title: values.title.trimStart(),
          status: true,
        });
        successPositionCreate();
        await delay(2000);
        if (submitGoTo === 'position') {
          window.location.href = `/${i18n.language}/app/positions/${newPosition}`;
        } else if (submitGoTo === 'applicants') {
          window.location.href = `/${i18n.language}/app/positions/${newPosition}/applicants`;
        }
        form.resetFields(['title']);
        closeModal();
      } catch (e: any) {
        generalError();
        setCreating(false);
      }
    } else {
      try {
        const newPosition: AppPositionElement = await updatePosition({ title: values.title }, positionId!);
        successPositionUpdated();
        router.push(`/app/positions/${positionId}`);
        closeModal();
        if (setTitle) setTitle(newPosition.title);
      } catch (e: any) {
        generalError();
        setCreating(false);
      }
    }
  };

  const onFinishFailed = () => {
    setOpenTitleModal(false);
  };

  const buttonText = () => {
    if (!positionId) {
      return translate('create_position.create_job');
    }
    return translate('create_position.save_position');
  };

  return (
    <Modal
      title={positionId ? translate('create_position.edit_title') : translate('create_position.new_position')}
      open={openTitleModal}
      className={styles.modalTitle}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        name="createPosition"
        form={form}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="antd-overwrite"
      >
        <Form.Item
          name="title"
          label={translate('create_position.position_title')}
          rules={[
            { required: true, message: translate('create_position.please_give_title') },
            {
              validator: (_, value) => {
                if (value !== '' && value.trimStart() === '') {
                  return Promise.reject(new Error(translate('create_position.title_no_spaces')));
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
          initialValue={titleValue}
        >
          <Input
            placeholder={translate('create_position.give_title')}
            onChange={(value) => {
              const trimmedValue = value.target.value.trimStart();
              if (trimmedValue) {
                setModalDisable(false);
              } else {
                setModalDisable(true);
              }
            }}
            data-testid="title-input"
            disabled={creating}
            prefix={creating && <Spin />}
          />
        </Form.Item>
        <div className={styles.modalButtons}>
          <UiButton
            type="primary"
            size="small"
            htmlType="submit"
            disabled={modalDisable || creating}
            onClick={() => setSubmitGoTo('position')}
            className={styles.modalCreateButton}
            data-testid="create-position-button"
          >
            {creating ? <Spin /> : buttonText()}
          </UiButton>
          {!positionId && (
            <UiButton
              type="ghost"
              size="small"
              htmlType="submit"
              disabled={modalDisable || creating}
              onClick={() => setSubmitGoTo('applicants')}
              className={styles.modalGoToButton}
              data-testid="create-position-button-applicants"
            >
              {creating ? <Spin /> : translate('create_position.create_internal_position')}
            </UiButton>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default TitleModal;
