import axiosInstance from '@utils/http-client';
import { UserInfoModel, PatchUserModel } from '@models/user';

const patchUser = (timeZone?: string, userName?: string, email?: string): Promise<PatchUserModel> => {
  return axiosInstance.patch(`/api/v1/app/user/info`, {
    userName,
    email,
    timeZone,
  });
};

export const getUserInfo = (): Promise<UserInfoModel> => {
  return axiosInstance.get(`/api/v1/app/user/info`, {});
};

export default patchUser;
