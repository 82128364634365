import { TermElement } from '@models/terms';
import { FormModel, PostCvValues } from '@models/form';
import ClientInfoModel from '@models/clientInfo';
import axiosInstance from '@utils/http-client';

export const getGeneralForm = (slug: string, lang: string): Promise<FormModel> => {
  return axiosInstance.get(`/api/v1/client/${slug}/form?type=general&lang=${lang}`, {});
};

export const getPositionForm = (slug: string, lang: string): Promise<FormModel> => {
  return axiosInstance.get(`/api/v1/client/${slug}/form?type=position&lang=${lang}`, {});
};

export const getClientInfo = (slug: string): Promise<ClientInfoModel> => {
  return axiosInstance.get(`/api/v1/client/${slug}/info`, {});
};

export const getTerms = (slug: string, lang: string): Promise<TermElement> => {
  return axiosInstance.get(`/api/v1/client/${slug}/terms?lang=${lang}`, {});
};

export const postCv = (formFields: PostCvValues): Promise<number> => {
  return axiosInstance.post(`/api/v1/client/${formFields.slug}/cv`, {
    recaptchaToken: formFields.recaptchaToken,
    lastName: formFields.lastName,
    firstName: formFields.firstName,
    email: formFields.email,
    residentialArea: formFields.residentialArea,
    address: formFields.address,
    addressNumber: formFields.addressNumber,
    gender: formFields.gender,
    drivingLicense: formFields.drivingLicense,
    residenceAreaList: formFields.residenceAreaList,
    postCode: formFields.postCode,
    dateOfBirth: formFields.dateOfBirth,
    mobilePhoneNumber: formFields.mobilePhoneNumber,
    educationLevel: formFields.educationLevel,
    location: formFields.location,
    department: formFields.department,
    position: formFields.position,
    employmentType: formFields.employmentType,
    informedBy: formFields.informedBy,
    phoneNumber: formFields.phoneNumber,
    comments: formFields.comments,
  });
};

export const postPositionCv = (positionId: string | null, formFields: PostCvValues): Promise<number> => {
  return axiosInstance.post(`/api/v1/client/${formFields.slug}/cv/${positionId}`, {
    recaptchaToken: formFields.recaptchaToken,
    lastName: formFields.lastName,
    firstName: formFields.firstName,
    email: formFields.email,
    residentialArea: formFields.residentialArea,
    address: formFields.address,
    gender: formFields.gender,
    drivingLicense: formFields.drivingLicense,
    residenceAreaList: formFields.residenceAreaList,
    addressNumber: formFields.addressNumber,
    postCode: formFields.postCode,
    dateOfBirth: formFields.dateOfBirth,
    mobilePhoneNumber: formFields.mobilePhoneNumber,
    educationLevel: formFields.educationLevel,
    location: formFields.location,
    department: formFields.department,
    position: formFields.position,
    employmentType: formFields.employmentType,
    informedBy: formFields.informedBy,
    phoneNumber: formFields.phoneNumber,
    comments: formFields.comments,
  });
};

export const uploadCv = (slug: string | null, person: string | null, file: FormData): Promise<FormData> => {
  return axiosInstance.post(`/api/v1/client/${slug}/cvFile/${person}`, file);
};
