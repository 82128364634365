enum RoutesEnum {
  HOMEPAGE = 'app/home',
  POSITIONS = 'app/positions',
  CVS = 'app/applicants',
  CALENDAR = 'app',
  CAREER = 'career',
  ALL_POSITIONS = 'positions/all',
  GENERAL_CV = 'form/general',
  CONTACT = 'contact',
  EMPLOYER_LOGIN = 'login',
}

export enum DefaultRoutesEnum {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  HOME_PAGE = 'HOME_PAGE',
}

export default RoutesEnum;
