import React, { useState } from 'react';
import { Form, Modal, Input, Spin, Row, Col, Upload, Button } from 'antd';
import UiButton from '@components/ui/button/UiButton';
import { useTranslation } from 'next-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import useNotification from '@hooks/notification/useNotification';
import StateEnum from '@constants/state.enum';
import { createApplicant, postCorrelatedPositions, uploadCV } from '@services/applicants/applicants-http.service';
import styles from './CandidateModal.module.scss';

type CandidateModalProps = {
  positionId?: string;
  openCandidateModal: boolean;
  fetchApplicants?: () => void;
  setOpenCandidateModal: (value: boolean) => void;
};

const CandidateModal: React.FC<CandidateModalProps> = ({
  openCandidateModal,
  setOpenCandidateModal,
  positionId,
  fetchApplicants,
}) => {
  const { t: translate } = useTranslation('applicant');
  const [creating, setCreating] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [uploadList, setUploadList] = useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState<FormData>();
  const [formField, setFormField] = useState({
    firstName: '',
    lastName: '',
  });

  const [uploadCvMessage] = useNotification(
    StateEnum.WARNING,
    'Unable to upload your CV',
    'Your CV must be smaller than 1.5MB',
  );

  const [generalError] = useNotification(
    StateEnum.ERROR,
    'Something went wrong',
    'Oops, something went wrong, please try again later!',
  );
  const [successCandidateCreate] = useNotification(
    StateEnum.SUCCESS,
    translate('add_candidate'),
    translate('successfully_add_candidate'),
  );

  const closeModal = () => {
    setCreating(false);
    setOpenCandidateModal(false);
  };

  const onFinish = async (values: {
    firstName: string;
    lastName: string;
    customLink?: string;
    email?: string;
    mobilePhoneNumber?: string;
  }) => {
    const delay = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
      const candidateId = await createApplicant(
        values.firstName,
        values.lastName,
        values.customLink,
        values.email,
        values.mobilePhoneNumber,
      );
      if (uploadedFile) await uploadCV(candidateId.toString(), uploadedFile);
      if (positionId) {
        await postCorrelatedPositions(candidateId, [Number(positionId)]);
        if (fetchApplicants) await fetchApplicants();
      }
      closeModal();
      successCandidateCreate();
      form.resetFields(['firstName', 'lastName', 'customLink', 'email', 'mobilePhoneNumber']);
      if (!positionId) {
        await delay(2000);
        window.location.reload();
      }
    } catch (e: any) {
      generalError();
      setCreating(false);
    }
  };

  const onFinishFailed = () => {
    setOpenCandidateModal(false);
  };

  const setupFileUploaded = (val: any) => {
    if (val.size < 1572864) {
      const data = new FormData();
      data.append('file', val.originFileObj);
      setUploadedFile(data);
    } else {
      setUploadedFile(undefined);
      form.resetFields(['cv_file']);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 1.5;
    if (!isLt2M) {
      uploadCvMessage('Unable to upload your CV');
      setUploadList(false);
      return isLt2M;
    }
    setUploadList(true);
    return isLt2M;
  };

  return (
    <Modal
      title={translate('add_applicant')}
      open={openCandidateModal}
      className={styles.modalTitle}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        name="createFirstName"
        layout="vertical"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="antd-overwrite"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label={translate('name')}
              rules={[{ required: true, message: translate('please_give_first_name') }]}
            >
              <Input
                placeholder={translate('first_name_placeholder')}
                onChange={(value) => {
                  setFormField({ ...formField, firstName: value.target.value });
                }}
                data-testid="first-name-input"
                disabled={creating}
                prefix={creating && <Spin />}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label={translate('last_name')}
              rules={[{ required: true, message: translate('please_give_last_name') }]}
            >
              <Input
                placeholder={translate('last_name_placeholder')}
                onChange={(value) => {
                  setFormField({ ...formField, lastName: value.target.value });
                }}
                data-testid="last-name-input"
                disabled={creating}
                prefix={creating && <Spin />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="customLink"
          label={translate('custom_link')}
          rules={[{ message: translate('please_give_link') }]}
        >
          <Input
            placeholder={translate('custom_link_placeholder')}
            data-testid="custom-link-input"
            disabled={creating}
            prefix={creating && <Spin />}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label={translate('candidate_modal_email')}
              rules={[{ message: translate('common:email_warning'), type: 'email' }]}
            >
              <Input
                placeholder={translate('candidate_modal_email')}
                data-testid="email-input"
                disabled={creating}
                prefix={creating && <Spin />}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobilePhoneNumber"
              label={translate('contact_number')}
              rules={[{ message: translate('please_give_contact_number') }]}
            >
              <Input
                placeholder={translate('contact_number')}
                data-testid="contact-number-input"
                disabled={creating}
                prefix={creating && <Spin />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="cv-file">
          <Upload
            onChange={(value) => setupFileUploaded(value.file)}
            accept=".doc,.docx,.pdf"
            maxCount={1}
            beforeUpload={beforeUpload}
            showUploadList={uploadList}
            onRemove={async () => {
              form.resetFields(['cv-file']);
              await form.validateFields(['cv-file']);
            }}
          >
            <Button icon={<UploadOutlined />}>{translate('upload_cv')}</Button>
            <div className={styles.wrapUploadText}>
              <div className={`h6 ${styles.uploadText}`}>{translate('common:file_type')}</div>
              <div className={`h6 ${styles.uploadText}`}>{translate('common:maximum_file_size')}</div>
            </div>
          </Upload>
        </Form.Item>
        <div className={styles.modalButtons}>
          <UiButton
            type="primary"
            size="small"
            htmlType="submit"
            disabled={!form.getFieldValue('firstName') || !form.getFieldValue('lastName') || creating}
            onClick={() => closeModal()}
            data-testid="create-candidate-button"
          >
            {creating ? <Spin /> : translate('create_candidate')}
          </UiButton>
          <UiButton
            type="ghost"
            size="small"
            htmlType="submit"
            onClick={() => closeModal()}
            data-testid="cancel_create_button"
          >
            {creating ? <Spin /> : translate('cancel_create_applicant')}
          </UiButton>
        </div>
      </Form>
    </Modal>
  );
};

export default CandidateModal;
