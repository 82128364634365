import React, { useCallback, useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { useRouter } from 'next/router';
import AppLayout from '@components/app-components/app-layout/AppLayout';
import useNotification from '@hooks/notification/useNotification';
import StateEnum from '@constants/state.enum';
import getConfig from 'next/config';
import { getClientInfo } from '@services/form/form-http.service';
import { UserInfoModel } from '@models/user';
import { getUserInfo } from '@services/user/user-http.service';
import ClientInfoModel from '@models/clientInfo';
import '../styles/globals.scss';

require('../styles/index.scss');

const {
  publicRuntimeConfig: { FRONTEND_URL },
} = getConfig();

function MyApp({ Component, pageProps }: AppProps) {
  const [clientInfo, setClientInfo] = useState<ClientInfoModel>();
  const [userInfo, setUserInfo] = useState<UserInfoModel>();

  const [, setLoading] = useState<boolean>(true);
  const router = useRouter();
  const slugClient = router.query.slug ? (router.query.slug as string) : '';

  const [generalError] = useNotification(
    StateEnum.ERROR,
    'Something went wrong',
    'Oops, something went wrong, please try again later!',
  );
  const [companyNotFoundError] = useNotification(
    StateEnum.ERROR,
    'Company not found',
    'Oops, the company you are trying does not exist.',
  );

  const fetchClientInfo = useCallback(async () => {
    if (slugClient) {
      try {
        const client: ClientInfoModel = await getClientInfo(slugClient);
        setClientInfo(client);
      } catch (e: any) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        e.ErrorMessage === 'company name not found' && companyNotFoundError();
        setLoading(true);
        generalError();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        router.pathname !== '/login' &&
        router.pathname !== '/forgot-password' &&
        router.pathname !== '/reset-password' &&
        router.pathname.startsWith('/app')
      ) {
        try {
          const user: UserInfoModel = await getUserInfo();
          setUserInfo(user);
          document.cookie = `timeZone=${user.timeZone || 'Europe/Athens'}`;
        } catch (e: any) {
          window.location.assign('/login');
        }
      }
    }
  }, [slugClient]);

  useEffect(() => {
    setLoading(true);
    fetchClientInfo();
  }, [fetchClientInfo]);

  const renderApp = () => {
    if (router.pathname.includes('app')) {
      return (
        <AppLayout companyInfo={userInfo?.company!}>
          <Component {...pageProps} />
        </AppLayout>
      );
    } else {
      return <Component {...pageProps} />;
    }
  };

  return (
    <>
      <Head>
        {slugClient ? (
          <>
            <title>{`${clientInfo?.name} | Θέσεις εργασίας`}</title>
            <link rel="icon" href={clientInfo?.favicon_url} />
          </>
        ) : (
          <>
            <title>HR.Prior</title>
            <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon/apple-touch-icon.png?v=1" />
            <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/favicon-32x32.png?v=1" />
            <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/favicon-16x16.png?v=1" />
          </>
        )}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta key="description" name="description" content="Βρες την δουλειά που σου ταιριάζει" />
        <meta key="og:title" property="og:title" content="Βρες την δουλειά που σου ταιριάζει" />
        <meta property="og:type" content="website" />
        <meta key="og:url" property="og:url" content={`${FRONTEND_URL}`} />
        <meta key="og:description" property="og:description" content="Βρες την δουλειά που σου ταιριάζει" />
        <meta key="og:image" property="og:image" content={`${FRONTEND_URL}/assets/images/kariera_default.png`} />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="4a057d2c-3948-4eb7-9397-8578b954c278"
          data-blockingmode="auto"
          type="text/javascript"
          async
        />
      </Head>
      {renderApp()}
    </>
  );
}

export const getServerSideProps = async ({ locale }: { locale: string }) => ({
  props: {
    ...(await serverSideTranslations(locale, [
      'common',
      'login',
      'applicants',
      'positions',
      'home',
      'company',
      'applicant',
      'createPosition',
      'position',
      'emailTemplates',
      'applicantCard',
      'form',
    ])),
  },
});

export default appWithTranslation(MyApp);
