import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Header } from '@components/app-components/app-header';
import BottomBar from '@components/bottom-bar/BottomBar';
import ClientInfoModel from '@models/clientInfo';
import styles from './AppLayout.module.scss';

const { Content } = Layout;

type PageLayoutProps = {
  children: React.ReactNode;
  companyInfo?: ClientInfoModel;
};

const AppLayout: React.FC<PageLayoutProps> = ({ children, companyInfo }) => {
  return (
    <>
      <Layout>
        <Header companyInfo={companyInfo!} />
        <Layout className={`${styles.mainColor}`}>
          <Layout className={`${styles.main}`}>
            <Content>{children}</Content>
          </Layout>
        </Layout>
        <BottomBar />
      </Layout>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

AppLayout.defaultProps = {
  companyInfo: {
    name: '',
    logo_url: {
      auth: '',
      search: '',
      href: '',
      slashes: null,
      query: '',
      path: '',
      hash: '',
      pathname: '',
      host: '',
      hostname: '',
      port: '',
      protocol: '',
    },
    id: 0,
    favicon_url: '',
    phone: '',
    adress: '',
    fax: '',
    map_url: '',
  },
};

export default AppLayout;
