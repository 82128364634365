import React, { useState } from 'react';
import { useRouter } from 'next/router';
import ClientInfoModel from '@models/clientInfo';
import { Dropdown, Image, Menu, Space } from 'antd';
import { logoutUser } from '@services/login/login-http.service';
import useNotification from '@hooks/notification/useNotification';
import StateEnum from '@constants/state.enum';
import HomeIcon from '@assets/svgs/home-icon.svg';
import CvsIcon from '@assets/svgs/red-people-icon.svg';
import BuildingIcon from '@assets/svgs/building-icon.svg';
import BigBuildingIcon from '@assets/svgs/big-building-icon.svg';
import GlobeIcon from '@assets/svgs/black-cyrcles-icon.svg';
import MailSettingIcon from '@assets/svgs/mail-settings-icon.svg';
import CaretDownIcon from '@assets/svgs/caret-icon-down.svg';
import AvatarIcon from '@assets/svgs/avatar-icon.svg';
import LogoutIcon from '@assets/svgs/logout-icon.svg';
import PositionsIcon from '@assets/svgs/main-menu-positions-icon.svg';
import WhitePlusIcon from '@assets/svgs/white-plus-icon.svg';
import RoutesEnum from '@constants/routes.enum';
import { useTranslation } from 'next-i18next';
import UiButton from '@components/ui/button/UiButton';
import TitleModal from '@components/app-components/app-header/nav-bar/title-modal/TitleModal';
import CandidateModal from '@components/app-components/app-header/nav-bar/candidate-modal/CandidateModal';
import styles from './NavBar.module.scss';

type NavBarProps = {
  companyInfo: ClientInfoModel;
};

export const NavBar: React.FC<NavBarProps> = ({ companyInfo }) => {
  const { t: translate, i18n } = useTranslation('common');
  const router = useRouter();
  const slugClient = router.query.slug ? (router.query.slug as string) : '';
  const [openTitleModal, setOpenTitleModal] = useState<boolean>(false);
  const [openCandidateModal, setOpenCandidateModal] = useState<boolean>(false);

  const [generalError] = useNotification(
    StateEnum.ERROR,
    'Something went wrong',
    'Oops, something went wrong, please try again later!',
  );

  function goTo(info: any) {
    router.push(`/${info.key}`);
  }

  const mainMenuItems = [
    {
      key: RoutesEnum.HOMEPAGE,
      icon: <HomeIcon />,
      label: translate('home'),
    },
    {
      key: RoutesEnum.POSITIONS,
      icon: <PositionsIcon />,
      label: translate('positions'),
    },
    {
      key: RoutesEnum.CVS,
      icon: <CvsIcon />,
      label: translate('cvs'),
    },
  ];

  const menu = (
    <Menu
      mode="horizontal"
      selectedKeys={[
        `${
          router.pathname.replace('/', '') === 'app/positions/create'
            ? RoutesEnum.POSITIONS
            : router.pathname.replace('/', '')
        }`,
      ]}
      className="menu-container"
      onClick={(info) => goTo(info)}
      items={mainMenuItems}
    />
  );

  const logoutFunc = async () => {
    try {
      await logoutUser();
      window.location.assign(`/${i18n.language}/login`);
    } catch (e: any) {
      generalError();
    }
  };

  const profileItems = [
    {
      key: 'preferences',
      icon: <GlobeIcon style={{ height: '12px', margin: '8px 8px' }} />,
      onClick: () => router.push(`/${i18n.language}/app/user/preferences`),
      label: translate('preferences'),
      className: styles.menuPadding,
    },
    {
      key: 'logout',
      icon: <LogoutIcon style={{ height: '12px', margin: '12px 8px' }} />,
      onClick: logoutFunc,
      label: translate('logout'),
      className: styles.menuPadding,
    },
  ];
  const profileMenu = () => {
    return <Menu mode="vertical" items={profileItems} />;
  };

  const settingsItems = [
    {
      key: 'company',
      icon: <BigBuildingIcon style={{ height: '17px', margin: '12px 8px' }} />,
      onClick: () => router.push(`/app/company`),
      label: translate('company_settings'),
      className: styles.menuPadding,
    },
    {
      key: 'emailTemplates',
      icon: <MailSettingIcon style={{ height: '17px', margin: '12px 8px' }} />,
      onClick: () => router.push(`/app/company/email-templates`),
      label: translate('email_templates'),
      className: styles.menuPadding,
    },
  ];

  const settingsMenu = () => {
    return <Menu mode="vertical" items={settingsItems} />;
  };

  const isApplicantsTab = router.pathname.includes('applicants');

  const mobileView = () => {
    return (
      <div className={`${styles.container} ${styles.mobile}`}>
        <div className={styles.logos}>
          {slugClient !== '' ? (
            <Image
              preview={false}
              src={`${companyInfo?.logo_url}`}
              alt={`${slugClient} logo`}
              className={styles.logo}
              data-testid="mobile-logo"
            />
          ) : (
            <Image
              preview={false}
              src={`${companyInfo?.logo_url}`}
              alt={`${slugClient} logo`}
              className={styles.logo_border}
              data-testid="mobile-logo"
            />
          )}
        </div>
        <div className={styles.profileMenus}>
          <div
            className={styles.gearButton}
            onClick={() => router.push(`/app/company`)}
            onKeyDown={() => router.push(`/app/company`)}
            role="presentation"
          >
            <BuildingIcon fill="red" />
          </div>
          <Dropdown dropdownRender={profileMenu} trigger={['click']} placement="bottomRight">
            <a onClick={(e) => e.preventDefault()} role="presentation">
              <Space>
                <div className={styles.avatarButton}>
                  <AvatarIcon style={{ height: '32px' }} />
                  <CaretDownIcon fill="red" />
                </div>
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    );
  };

  const desktopView = () => {
    return (
      <div className={styles.containerDesktopWrap}>
        <TitleModal openTitleModal={openTitleModal} setOpenTitleModal={setOpenTitleModal} />
        <CandidateModal openCandidateModal={openCandidateModal} setOpenCandidateModal={setOpenCandidateModal} />
        <div className={`${styles.container} ${styles.desktop}`}>
          <div className={styles.logos}>
            <Image
              preview={false}
              src={`${companyInfo?.logo_url}`}
              alt={`${slugClient} logo`}
              className={styles.logo}
              data-testid="mobile-logo"
            />
            {slugClient !== '' ? (
              <div className={styles.logoDesktop}>
                <Image
                  onClick={() => router.push(`/app/home`)}
                  preview={false}
                  src={`${companyInfo?.logo_url}`}
                  alt={`${slugClient} logo`}
                />
              </div>
            ) : (
              <>
                <Image
                  onClick={() => router.push(`/app/home`)}
                  preview={false}
                  src="/assets/images/hrprior-dark.png"
                  alt="HRprior Logo"
                  className={styles.logoDesktop_hrprior}
                />
                <Image
                  onClick={() => router.push(`/app/home`)}
                  preview={false}
                  src={`${companyInfo?.logo_url}`}
                  alt={`${slugClient} logo`}
                  className={styles.logoDesktop_user}
                />
              </>
            )}
          </div>
          <div className={styles.profileMenus}>
            <Dropdown dropdownRender={settingsMenu} trigger={['click']} placement="bottomRight">
              <a onClick={(e) => e.preventDefault()} role="presentation">
                <Space>
                  <div className={styles.gearButton}>
                    <BuildingIcon fill="red" />
                  </div>
                </Space>
              </a>
            </Dropdown>
            <Dropdown dropdownRender={profileMenu} trigger={['click']} placement="bottomRight">
              <a onClick={(e) => e.preventDefault()} role="presentation">
                <Space>
                  <div className={styles.avatarButton}>
                    <AvatarIcon style={{ height: '32px' }} />
                  </div>
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
        {router.pathname !== '/app/positions/[positionId]/applicants' && (
          <>
            <hr style={{ border: '1px solid #F2F2F6', width: '100%' }} />
            <div className={styles.mainMenuWrap}>
              {menu}
              {router.pathname !== '/app/home' && (
                <div className={styles.wrapMenuButton}>
                  <UiButton
                    className={styles.newPositionButton}
                    type="primary"
                    size="small"
                    onClick={() => (isApplicantsTab ? setOpenCandidateModal(true) : setOpenTitleModal(true))}
                    icon={<WhitePlusIcon />}
                  >
                    {isApplicantsTab ? translate('add_candidate') : translate('new_position')}
                  </UiButton>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={styles.compWrap}>
      {mobileView()}
      {desktopView()}
    </div>
  );
};

export default NavBar;
