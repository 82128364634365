import axiosInstance from '@utils/http-client';

export function loginUser(username: string, password: string): Promise<any> {
  return axiosInstance.post('/api/v1/app/user/login', { username, password });
}

export function logoutUser(): Promise<any> {
  return axiosInstance.post('/api/v1/app/user/logout', {});
}

export function forgotPassword(username: string): Promise<any> {
  return axiosInstance.post('/api/v1/app/user/forgot-password', { username });
}

export function resetPassword(token: string, password: string): Promise<any> {
  return axiosInstance.post('/api/v1/app/user/reset-password', { token, password });
}
