import React from 'react';
import HomeIcon from '@assets/svgs/home-icon.svg';
import IndutryIcon from '@assets/svgs/industry.svg';
import CalendarIcon from '@assets/svgs/calendar-icon.svg';
import DocumentIcon from '@assets/svgs/document-icon.svg';
import RoutesEnum from '@constants/routes.enum';
import { Menu } from 'antd';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styles from './BottomBar.module.scss';

const BottomBar = () => {
  const { t: translate } = useTranslation('common');
  const router = useRouter();

  function goTo(info: any) {
    router.push(`/${info.key}`);
  }

  const menuItems = [
    {
      key: RoutesEnum.HOMEPAGE,
      icon: <HomeIcon />,
      label: translate('home'),
    },
    {
      key: RoutesEnum.POSITIONS,
      icon: <IndutryIcon />,
      label: translate('positions'),
    },
    {
      key: RoutesEnum.CVS,
      icon: <DocumentIcon />,
      label: translate('cvs'),
    },
    {
      key: RoutesEnum.CALENDAR,
      icon: <CalendarIcon />,
      label: translate('calendar'),
    },
  ];

  const menu = (
    <Menu
      data-testid="bottom-bar"
      mode="horizontal"
      selectedKeys={[`${router.pathname.replace('/', '')}`]}
      className="bottom-bar-container"
      onClick={(info) => goTo(info)}
      items={menuItems}
    />
  );

  return <section className={styles.compWrap}>{menu}</section>;
};

export default BottomBar;
