import React from 'react';
import { Button, ButtonProps } from 'antd';
import PropTypes from 'prop-types';

export interface UiButtonProps extends ButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const UiButton: React.FC<UiButtonProps> = ({ children, onClick, className, ...rest }) => {
  return (
    <Button onClick={onClick} {...rest} className={`ui-button ${className}`}>
      {children}
    </Button>
  );
};

UiButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

UiButton.defaultProps = {
  onClick: () => {},
  children: '',
  className: '',
};

export default UiButton;
