import React from 'react';
import { NavBar } from '@components/app-components/app-header/nav-bar/NavBar';
import ClientInfoModel from '@models/clientInfo';
import styles from './header.module.scss';

type HeaderProps = {
  companyInfo: ClientInfoModel;
};

export const Header: React.FC<HeaderProps> = ({ companyInfo }) => {
  return (
    <>
      <header className={styles.header}>
        <NavBar companyInfo={companyInfo!} />
      </header>
    </>
  );
};

export default Header;
