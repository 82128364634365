import {
  AppPositionElement,
  CreatePositionValues,
  PositionElement,
  UpdatePositionValues,
} from '../../models/positions';
import axiosInstance from '../../utils/http-client';

export const getPosition = (slug: string, positionId: string, lang: string): Promise<PositionElement> => {
  return axiosInstance.get(`/api/v1/client/${slug}/positions/${positionId}?lang=${lang}`, {});
};

export const getAppPosition = (positionId: string, lang?: string): Promise<AppPositionElement> => {
  if (lang) return axiosInstance.get(`/api/v1/app/positions/${positionId}?lang=${lang}`, {});
  return axiosInstance.get(`/api/v1/app/positions/${positionId}`, {});
};

export const createPosition = (positionValues: CreatePositionValues): Promise<AppPositionElement> => {
  return axiosInstance.post('/api/v1/app/position', {
    title: positionValues.title,
    description: positionValues.description,
    code: positionValues.code,
    department: positionValues.department,
    location: positionValues.location,
    employment_type: positionValues.employment_type,
    status: positionValues.status,
    posted: positionValues.posted,
  });
};

export const updatePosition = (
  positionValues: UpdatePositionValues,
  positionId: string,
  lang?: string,
): Promise<AppPositionElement> => {
  if (lang) {
    return axiosInstance.patch(`/api/v1/app/positions/${positionId}?lang=${lang}`, {
      title: positionValues.title,
      description: positionValues.description,
      code: positionValues.code,
      department: positionValues.department,
      location: positionValues.location,
      remote: positionValues.remote,
      jobCategory: positionValues.jobCategory,
      experienceLevel: positionValues.experienceLevel,
      employment_type: positionValues.employment_type,
      status: positionValues.status,
      posted: positionValues.posted,
    });
  }
  return axiosInstance.patch(`/api/v1/app/positions/${positionId}`, {
    title: positionValues.title,
    description: positionValues.description,
    code: positionValues.code,
    department: positionValues.department,
    location: positionValues.location,
    remote: positionValues.remote,
    jobCategory: positionValues.jobCategory,
    experienceLevel: positionValues.experienceLevel,
    employment_type: positionValues.employment_type,
    status: positionValues.status,
    posted: positionValues.posted,
  });
};

export default getPosition;
